































































































































































import Vue from 'vue'
import axios from 'axios'

interface Choice {
  display: string;  
  id: string;       
  roomName: string;
  text: string;     
}

export default Vue.extend({
  name: 'AddRoom',
  data() {
    return {
      roomName: '',
      roomName2: '',
      dialogOpen: false,
      dis1: true,
      dis2: true,
      dis3: true,
      dis4: true,
      disqr: true,
      val1: '',
      val2: '',
      val3: '',
      val4: '',
      errorMessage1: '',
      errorMessage: '',
      successMessage: '', 
      inputPromptMessage: '',
      roomUrl: '',
      noResultsMessage: '',  
      filteredResults: [] as Choice[],
      choices:[] as Choice[],
      roomNameRules: [
        (v: string) => !!v || 'ルーム名は必須です。',
        (v: string) => !(/[!@#$%^&*(),?":{}|<> ]/.test(v)) || 'ルーム名に使用できない文字が含まれています。',
        (v: string) => (v && v.length <= 20) || 'ルーム名は20文字以内である必要があります。'
      ]
    }
  },
  methods: {
    setChoices() {
      let apiUrl = 'https://' + process.env.VUE_APP_API_URL + '/choices';
      let data = {
        "A":{txt:this.val1,display:this.dis1},
        "B":{txt:this.val2,display:this.dis2},  
        "C":{txt:this.val3,display:this.dis3},
        "D":{txt:this.val4,display:this.dis4},
        "QR":{display:this.disqr},
        "roomName": {roomName: this.roomName}
      }
      axios.post(apiUrl, data).then(response => {
        if (response.status != 200) {
          console.log('Registration failed.');
        }
      });
    },

    getChoices() {
      let apiUrl = 'https://' + process.env.VUE_APP_API_URL + '/choices';
      axios.get(apiUrl).then(response => {
        this.choices = response.data;
        this.searchRoom2();
      });
    },

    searchRoom2() {
      this.inputPromptMessage = '';
      this.noResultsMessage = '';
      // ルーム名が入力されていない場合の処理
      if (!this.roomName2) {
        this.inputPromptMessage = 'ルーム名を入力してください';
        this.filteredResults = [];
        this.noResultsMessage = '';  
        return;
      }

    
      const keyword = this.roomName2.toLowerCase();
      this.filteredResults = this.choices.filter(choice => 
        choice.roomName.toLowerCase().includes(keyword) && choice.id === "A"
      );

      // 検索結果が0の場合の処理
      if (this.filteredResults.length === 0) {
        this.noResultsMessage = 'ルームが存在しません';
      } else {
        this.noResultsMessage = '';  
      }
    },
    
    openCreateRoom() {
      this.dialogOpen = true;
    },
    closeCreateRoom() {
      this.dialogOpen = false;
    },
    checkRoomName() {
      this.getChoicesForValidation().then(() => {
        if (this.isRoomNameExists()) {
          this.errorMessage = `${this.roomName}は既に存在します`;
          this.successMessage = '';
          this.roomUrl = '';
        } else {
          this.errorMessage = '';
          this.successMessage = 'ルームが作成されました';
          this.roomUrl = `https://ics-rooms.eggshowcase.com/home/${this.roomName}`;
          // this.roomUrl = `https://ics3.eggshowcase.com/home/${this.roomName}`;
          this.setChoices();
        }
      });
    },

    getChoicesForValidation() {
      let apiUrl = 'https://' + process.env.VUE_APP_API_URL + '/choices';
      return axios.get(apiUrl).then(response => {
        this.choices = response.data;
      });
    },

    isRoomNameExists() {
      return this.choices.some(choice => choice.roomName === this.roomName);
    }
  }
})
